import "focus-visible";
import React, { FunctionComponent } from "react";
import HtmlHead, { HtmlHeadProps } from "./htmlHead";
import "./masterLayout.scss";

const MasterLayout: FunctionComponent<HtmlHeadProps> = ({
  title,
  description,
  children,
}) => (
  <>
    <HtmlHead title={title} description={description} />
    {children}
  </>
);
export default MasterLayout;
