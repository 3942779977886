import { graphql, useStaticQuery } from "gatsby";
import React, { ReactElement } from "react";
import Helmet from "react-helmet";

export interface HtmlHeadProps {
  title: string;
  description?: string;
}

export default function HtmlHead({
  description,
  title,
}: HtmlHeadProps): ReactElement {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
      link={[
        {
          href: "https://fonts.googleapis.com/css?family=Rubik|Karla",
          rel: "stylesheet",
        },
      ]}
    />
  );
}
